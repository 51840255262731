jQuery(document).ready(function($) {
  $('.site-header .scroll-btn').click(
    function(event) {
      var $nextSection = $('.site-main');
      var $navbar = $('.navbar');
      var height = $nextSection.offset().top -
      $navbar.addClass('navbar--shrink').height();
      event.preventDefault();
      $('html, body').animate({
        scrollTop: height,
        easing: 'easeInOut'
      }, 500);
    }
  );

  $('.site-main .scroll-btn').click(
    function(event) {
      var $nextSection = $(this).parents('section').next();
      var $navbar = $('.navbar');
      var height = $nextSection.offset().top -
      $navbar.addClass('navbar--shrink').height();
      event.preventDefault();
      $('html, body').animate({
        scrollTop: height,
        easing: 'easeInOut'
      }, 500);
    }
  );

  $('#main-nav-toggle').click(
    function(event) {
      event.preventDefault();
      $(this).blur();
      if ($('body').hasClass('navbar--toggled')) {
        $('body').removeClass('navbar--toggled');
      } else {
        $('body').addClass('navbar--toggled');
      }
    }
  );

  $('.hamburger').click(
    function(event) {
      event.preventDefault();
      $(this).blur();
      if ($(this).hasClass('is-active')) {
        $(this).removeClass('is-active');
      } else {
        $(this).addClass('is-active');
      }
    }
  );

  $('.btn-scrollTop').click(
    function(event) {
      $('html, body').animate({scrollTop: $('header').offset().top}, 'slow');
    }
  );

  $(window).scroll(function() {
    var $navbar = $('.navbar');
    if ($navbar.hasClass('navbar--switch')) {
      if ($(document).scrollTop() > 50) {
        $navbar.removeClass('navbar--transparent');
      } else {
        $navbar.addClass('navbar--transparent');
      }
    }
    if ($(document).scrollTop() < 100) {
      $navbar.removeClass('navbar--shrink');
    } else {
      $navbar.addClass('navbar--shrink');
    }
  });

  $('#fb-share-btn').click(
    function(e) {
      e.preventDefault();
      FB.ui({
        method: 'share',
        href: window.location.href
      }, function(response) {});
    }
  );

  $('#slider').flickity({
    // options
    cellAlign: 'left',
    autoPlay: 4000,
    pauseAutoPlayOnHover: false,
    setGallerySize: false,
    prevNextButtons: false,
    wrapAround: true
  });

  // fire scroll on page load for navbar
  $(window).scroll();

  // init facebook sdk
  window.fbAsyncInit = function() {
    FB.init({
      appId: '1605010493141695',
      xfbml: true,
      version: 'v2.8'
    });
    FB.AppEvents.logPageView();
  };

  // featherlight global options
  $.featherlight.defaults.closeIcon =
  '<i class="fa fa-times fa-2x" aria-hidden="true"></i>';
  $.featherlight.defaults.loading =
  '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>';

  // activate featherlight on links
  $('.lightbox-btn').featherlight($('#lightbox'), {persist: 'shared'});

  // activate fitvids on entry-content
  $('#entry-content,#entry-content-main,#entry-content-aux').fitVids();
});
